<template>

  <v-app>


    <TalonNavbar/>


    <v-main>
      <v-container>
          <router-view/>
      </v-container>
    </v-main>



    <TalonFooter/>
  </v-app>


</template>

<script>
  import TalonFooter from '@/components/app/TalonFooter'
  import TalonNavbar from '@/components/app/TalonNavbar'

  export default {
    name: 'App',
    components: {
      TalonNavbar, TalonFooter
    },
    //mounted(){
      //console.log('NNODE_TLS_REJECT_UNAUTHORIZED=',process.env.NNODE_TLS_REJECT_UNAUTHORIZED)
      //console.log('NODE_TLS_REJECT_UNAUTHORIZEDD=',process.env.NODE_TLS_REJECT_UNAUTHORIZEDD)
      //console.log('VUE_APP_NOT_SECRET_CODE=',process.env.VUE_APP_NOT_SECRET_CODE)
      //console.log('env=',process.env)
    //},
    created() {
      this.$store.dispatch('SET_APP', {
        path: '/'
      })
    },
    computed: {
      APP() {
        return this.$store.getters.APP;
      },

    }

  }

</script>
