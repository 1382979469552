export default {
    state: {
        arr: {}
    },
    getters:{
        DDATE: state=> {
            return state.arr
        }
    },
    mutations: {
        SET_DDATE: (state,payload) =>{
            state.arr=payload
            state.arr.actual='1'
        },
        SET_DDATE_ACTUAL: (state,actual) =>{
            state.arr.actual=actual
        }
    },
    actions: {
        SET_DDATE: (context, payload) => {
             context.commit('SET_DDATE',payload)
        },
        SET_DDATE_ACTUAL: (context, actual) => {
            context.commit('SET_DDATE_ACTUAL',actual)
        }
    },

}