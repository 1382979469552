<template>
    <v-container fluid>
        <v-row align="center">
            <v-col
                    class="d-flex"
                    cols="12"
                    md="4"
            >
                <v-text-field
                        ref="P_SER"
                        v-model="P_SER"
                        label="Серия полиса (если есть)"
                        placeholder="Если есть"
                ></v-text-field>
            </v-col>
            <v-col
                    class="d-flex"
                    cols="12"
                    md="8"
            >
                <v-text-field
                        ref="P_NUM"
                        v-model="P_NUM"
                        label="Номер полиса"
                        placeholder="123456789012345"
                        :rules="numRules"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {
        name: "InputPolicy",

        data: () => ({
            /* использует данные родителя через prop pthis
            p_ser: '',
            p_num: '',
            */
            numRules: [
                v => !!v || 'Обязательно для заполнения',
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length >=6 && v.length <=16) || 'Количество цифр полиса должно быть от 6 до 16'
            ]


        }),
        computed:{
            P_SER: {
                // getter
                get: function () {
                    return this.pthis.p_ser
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_ser=newValue
                }
            },
            P_NUM: {
                // getter
                get: function () {
                    return this.pthis.p_num
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_num=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>