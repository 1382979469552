export default {
    state: {
        arr: {}
    },
    getters:{
        LPU: state=> {
            return state.arr
        }
    },
    mutations: {
        SET_LPU: (state,payload) =>{
            state.arr=payload
            state.arr.actual='1'
        }
    },
    actions: {
        SET_LPU: (context, payload) => {
             context.commit('SET_LPU',payload)
        },
        SET_LPU_ACTUAL: (context, actual) => {
            context.commit('SET_LPU_ACTUAL',actual)
        }
    },

}