import JSON_to_URLEncoded from "./json2url";
import axios from "axios";
import https from 'https'

export default function post(url, jsonData){
    //process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
    //console.log('++process.env.NODE_TLS_REJECT_UNAUTHORIZED',process.env, process.env.NODE_ENV)

    const urlData=JSON_to_URLEncoded(jsonData)
    const axiosConfig= {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
//        hostname: 'stom4.talon74.ru',
        httpsAgent: new https.Agent({
            agent: false,
            rejectUnauthorized: false,
            ca: '', //fs.readFileSync(`CA.pem`),
            cert: '', //fs

        })
    }

    return new Promise((resolve,reject) => {
        axios.post(url, urlData, axiosConfig)
            .then((res) => {
                resolve(res)

            })
            .catch((err) => {
                reject(err)
            })
    })
}

//---------------------------------------------------------------------------------------------------
async function postLog(url,arr) {
    //console.log('postLog',url,arr)
    return post(url + '/log',arr )
}




export {postLog} // список экспортируемых переменных
