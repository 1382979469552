<template>
    <v-container fluid>
        <v-row align="center">
            <v-col
                    class="d-flex"
                    cols="12"
                    md="5"
            >
                <v-text-field
                        ref="P_EMAIL"
                        v-model="P_EMAIL"
                        label="Email"
                        placeholder="ivanov@mail.ru"
                        :rules="emailRules"
                ></v-text-field>
            </v-col>
            <v-col
                    class="d-flex"
                    cols="12"
                    md="7"
            >
                <v-text-field
                        ref="P_TEL"
                        v-model="P_TEL"
                        label="Телефон для связи"
                        placeholder="89080123456"
                        :rules="telRules"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {
        name: "InputEmailTel",

        data: () => ({
            /* использует данные родителя через prop pthis
            p_email: '',
            p_tel: '',
            */

            /*
            emailRules: [
                v => !!v || 'Обязательно для заполнения!',
                v => /.+@.+/.test(v) || 'Некорректный E-mail'
            ],
            telRules:[
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length == 11) || 'Телефон должен содержать 11 цифр'
            ]
            */
            emailRules: [
                v => /.+@.+/.test(v) || 'Некорректный E-mail'

            ],
            telRules:[
                v => !!v || 'Обязательно для заполнения!',
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length == 11) || 'Телефон должен содержать 11 цифр'
            ]


        }),
        computed:{
            P_EMAIL: {
                // getter
                get: function () {
                    return this.pthis.p_email
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_email=newValue
                }
            },
            P_TEL: {
                // getter
                get: function () {
                    return this.pthis.p_tel
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_tel=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>