<template>
    <v-container fluid>
        <v-checkbox
                v-model="P_AGREE"
                label="Согласен передать данные в обработку согласно ФЗ №152"
                :error="!P_AGREE"
        ></v-checkbox>
    </v-container>

</template>

<script>
    export default {
        name: "InputAgree",

        data: () => ({
            /* использует данные родителя через prop pthis
            p_agree: false,
            */
            none: ''

        }),
        computed:{
            P_AGREE: {
                // getter
                get: function () {
                    return this.pthis.p_agree
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_agree=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>