<template>

    <v-footer
            padless
            class="text-center"
    >
        <v-card
                flat
                tile
                width="100%"
        >
            <v-card-text
                    class="gray--text"
            >
                Данный сервис разработан для комфортной записи в лечебные учреждения.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
                <a href="https://liner74.ru">&copy; 2010-{{curyear}} ver 06.10, talon2, Copyright ООО <q>Лайнер</q></a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>

    export default {
        name: 'TalonFooter',
        computed: {
            curyear() {
                return new Date().getFullYear()
            }
        }

    }

</script>