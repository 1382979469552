import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'
import messagePlugin from '@/tools/message.plugin.js'
import vuetify from './plugins/vuetify';

/*
19.01.2021
 проверка на FREE med,date,ttime
19.01.2021
 при переходах проверяется актуальность selectedItem
 SET_KARD_ACTUAL ...

*/


Vue.config.productionTip = false
process.env.NODE_ENV === 'production'
/*
sudo systemctl set-default multi-user
sudo systemctl set-default graphical
 */
Vue.use(messagePlugin)
Vue.use(Vuelidate)
Vue.filter('date',dateFilter)

new Vue({
  router,
  store,

  beforeCreate() {
    this.$store.commit('initialiseStore')
    // Subscribe to store updates
    this.$store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      sessionStorage.setItem('store', JSON.stringify(state));
    });
  },
  vuetify,
  render: h => h(App)
}).$mount('#app')
