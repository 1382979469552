<template>

    <!-- Кнопки выбора карточки 1,2,3 -->
    <div>
        <v-tooltip bottom
                   v-for="(e,i) in kardCaption"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn  class="mx-2"
                        fab
                        dark
                        small
                        :color="btnColor(i)"
                        @click="btnClick(i)"
                        :key="i"
                        v-bind="attrs"
                        v-on="on"
                >
                    {{e}}
                </v-btn>
            </template>
            <span>Данные {{e}} человека</span>
        </v-tooltip>
    </div>

</template>

<script>
    import {doRest, doSave, kardIndex} from "./app/js/kard";

    export default {
        name: "InputKardBtn3",

        data: () => ({
            /* использует данные родителя через prop pthis
            kardIndex: 0,

            */
            kardCaption: ['1', '2', '3']

        }),
        computed:{
            KARDINDEX: {
                // getter
                get: function () {
                    return this.pthis.kardIndex
                },
                // setter
                set: function (newValue) {
                    this.pthis.kardIndex=newValue
                }
            }

        },
        methods: {

            btnColor(i) {
                return i == this.pthis.kardIndex ? 'primary' : 'grey'
            },
            btnClick(i) {
                doSave(this.pthis, this.pthis.kardIndex)
                doSave(this.pthis) //в текущие данные
                this.pthis.kardIndex=i
                doRest(this.pthis, this.pthis.kardIndex)
            }
        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>