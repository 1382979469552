export default {
    state: {
        arr: {}
    },
    getters:{
        STA: state=> {
            return state.arr
        }
    },
    mutations: {
        SET_STA: (state,payload) =>{
            state.arr=payload
            state.arr.actual='1'
        },
        SET_STA_ACTUAL: (state,actual) =>{
            state.arr.actual=actual
        }
    },
    actions: {
        SET_STA: (context, payload) => {
             context.commit('SET_STA',payload)
        },
        SET_STA_ACTUAL: (context, actual) => {
            context.commit('SET_STA_ACTUAL',actual)
        }
    },

}