<template>
<nav>
    <v-navigation-drawer
            v-model="drawer"
            temporary
            app
    >
        <v-list
                nav
                dense
                app
        >
            <v-list-item-group
                    active-class="blue--text text--accent-4"
            >
                <v-list-item
                        @click="drawer = !drawer;"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconClose}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Закрыть меню</v-list-item-title>
                </v-list-item>

                <v-list-item
                        @click="navHome()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconHome}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Домой</v-list-item-title>
                </v-list-item>

                <v-list-item
                        :disabled="!next"
                        @click="navVideohelp()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconPlayCircleOutline}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Видеоинструкции</v-list-item-title>
                </v-list-item>

                <v-list-item
                        :disabled="!back"
                        @click="navBack()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconBack}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Обратно</v-list-item-title>
                </v-list-item>

                <v-list-item
                        :disabled="!next"
                        @click="navNext()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconNext}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Дальше</v-list-item-title>
                </v-list-item>


                <v-list-item
                        @click="navSubscribe()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconNotify}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Условия использоания сервиса</v-list-item-title>
                </v-list-item>


                <!--v-list-item>
                    <v-list-item-icon>
                        <v-icon>{{iconApp}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Для медучреждений</v-list-item-title>
                </v-list-item-->


            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>


    <v-app-bar app
               color="primary"
               dense
               dark
    >
        <!--v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon @click="drawer = !drawer;"
                                    v-bind="attrs"
                                    v-on="on"
                >

                </v-app-bar-nav-icon>
            </template>
            <span>Меню</span>
        </v-tooltip-->

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">

                <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="drawer = !drawer;"
                >
                    <v-icon>
                        {{iconMenu}}
                    </v-icon>
                </v-btn>
            </template>
            <span>Меню</span>
        </v-tooltip>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">

                <v-btn
                        icon
                        :disabled="!back"
                        v-bind="attrs"
                        v-on="on"
                        @click="navBack()"
                >
                    <v-icon>
                        {{iconBack}}
                    </v-icon>
                </v-btn>
            </template>
            <span>Обратно</span>
        </v-tooltip>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       :disabled="!next"
                       v-bind="attrs"
                       v-on="on"
                       @click="navNext()"
                >
                    <v-icon>{{iconNext}}</v-icon>
                </v-btn>
            </template>
            <span>Дальше</span>
        </v-tooltip>


        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                        v-bind="attrs"
                        v-on="on"
                        @click="navHome(); drawer=false"
                >
                    Talon74.ru
                </v-toolbar-title>
            </template>
            <span>Talon74.ru - cайт записи к врачу через Интернет</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                        class="hidden-md-and-down"
                        v-bind="attrs"
                        v-on="on"
                        @click="navHome(); drawer=false"
                >
                    Cайт записи к врачу через интернет
                </v-toolbar-title>
            </template>
            <span>Talon74.ru</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <!--v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       class="hidden-md-and-up"
                       v-bind="attrs"
                       v-on="on"
                       @click="navSubscribe()"
                >
                    <v-icon>{{iconNotify}}</v-icon>
                </v-btn>
            </template>
            <span>Условия использования сервиса</span>
        </v-tooltip-->


        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       class="hidden-md-and-up"
                       v-bind="attrs"
                       v-on="on"
                       @click="navVideohelp()"
                >
                    <v-icon>{{iconPlayCircleOutline}}</v-icon>
                </v-btn>
            </template>
            <span>Видеоинструкции</span>
        </v-tooltip>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="hidden-md-and-down"
                       v-bind="attrs"
                       v-on="on"
                       @click="navVideohelp(); drawer=false"
                >
                    Видеоинструкции
                </v-btn>
            </template>
            <span>Посмотрите видеоинструкции использования сервиса</span>
        </v-tooltip>
        <!--v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="hidden-md-and-down"
                       v-bind="attrs"
                       v-on="on"
                       @click="navSubscribe(); drawer=false"
                >
                    Условия использования сервиса
                </v-btn>
            </template>
            <span>Пожалуйста, ознакомьтесь с условиями использования сервиса</span>
        </v-tooltip-->

    </v-app-bar>
</nav>

</template>

<script>

    import { mdiChevronLeft, mdiChevronRight, mdiClose, mdiHome, mdiHomeOutline, mdiApplication, mdiMessageAlertOutline, mdiMenu, mdiPlayCircleOutline} from '@mdi/js';
    import {toBack, toNext} from '@/components/app/js/globals'

    export default {
        name: 'TalonNavbar',
        data: () => ({
            back: true,
            next: true,
            drawer: false,
            iconBack: mdiChevronLeft,
            iconNext: mdiChevronRight,
            iconClose: mdiClose,
            iconHome: mdiHomeOutline,
            iconApp: mdiApplication,
            iconNotify: mdiMessageAlertOutline,
            iconMenu: mdiMenu,
            iconPlayCircleOutline: mdiPlayCircleOutline
        }),
        methods:{
            async navHome(){
                this.drawer=false
                try {
                    localStorage.setItem('lpuid','') //сброисть lpuid
                    await this.$router.push('/')
                } catch (err) {
                    throw new Error(`Что-то пошло не так (navHome): ${err})`)
                }
            },
            async navSubscribe(){
                this.drawer=false
                try {
                    await this.$router.push('/subscribe/#subscribe-top')
                } catch (err) {
                    throw new Error(`Что-то пошло не так (navSubsribe): ${err})`)
                }
            },
            async navVideohelp(){
                this.drawer=false
                try {
                    await this.$router.push('/videohelp')
                } catch (err) {
                    throw new Error(`Что-то пошло не так (navVideohelp): ${err})`)
                }
            },
            async navBack(){
                toBack(this)
            },
            async navNext(){
                toNext(this)
            }
        }

    }

</script>
