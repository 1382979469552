export default {
    state: {
        arr: {}
    },
    getters:{
        MEDG: state=> {
            return state.arr
        }
    },
    mutations: {
        SET_MEDG: (state,payload) =>{
            state.arr=payload
            state.arr.actual='1'
        },
        SET_MEDG_ACTUAL: (state,actual) =>{
            state.arr.actual=actual
        }
    },
    actions: {
        SET_MEDG: (context, payload) => {
             context.commit('SET_MEDG',payload)
        },
        SET_MEDG_ACTUAL: (context, actual) => {
            context.commit('SET_MEDG_ACTUAL',actual)
        }
    },

}