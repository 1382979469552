var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"inline"},[_c('h2',{staticClass:"mr-md-3"},[_vm._v(_vm._s(_vm.HEADER))]),_c('v-btn',{staticClass:"mx-2",staticStyle:{"visibility":"hidden"},attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" 1 ")])],1),_c('br'),_c('div',{staticClass:"inline"},[_c('h5',[_vm._v(_vm._s(_vm.FIO))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.gotoKard()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconRight))])],1)]}}])},[_c('span',[_vm._v("Ввести предварительные данные для записи на прием")])])],1),_vm._l((_vm.arr),function(e,i){return _c('v-card',{key:e.npp,staticClass:"mt-6",attrs:{"elevation":"10","shaped":""},model:{value:(_vm.arr),callback:function ($$v) {_vm.arr=$$v},expression:"arr"}},[_c('v-card-title',{staticClass:"primary white--text",domProps:{"innerHTML":_vm._s(e.name)}}),_c('v-card-subtitle',{staticClass:"mt-4"},[_vm._v(_vm._s(e.address))]),_c('v-card-actions',{staticClass:"flex-wrap align-content-center"},[_c('v-btn',{staticClass:"ml-3 mr-3 mt-3  mb-3",attrs:{"color":"primary","value":e.npp},on:{"click":function($event){$event.preventDefault();return _vm.onNext(i)}}},[_vm._v(" "+_vm._s(_vm.$data.captionSubmit)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 mb-3 ml-3 mr-3",attrs:{"value":e.npp},on:{"click":function($event){$event.preventDefault();return _vm.onCancel(i)}}},[_vm._v(" "+_vm._s(_vm.$data.captionCancel)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){_vm.show = !_vm.show}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.show ? _vm.iconUp : _vm.iconDown))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.show ? 'Свернуть' : 'Дополнительно1'))])])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),_c('v-card-actions',{staticClass:"flex-wrap align-content-center"},[_c('v-btn',{staticClass:"mt-3 mb-3 ml-3 mr-3",attrs:{"href":e.telto}},[_vm._v(" "+_vm._s(e.tel)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 mb-3 ml-3 mr-3",attrs:{"href":e.mailto}},[_vm._v(" Написать главному врачу ")])],1)],1)])],1)})],2),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"id":"kard","elevation":"0"}},[_c('div',{staticClass:"inline"},[_c('h2',{staticClass:"mr-md-3"},[_vm._v(" Ваши данные ")]),_vm._l((_vm.kardCaption),function(e,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:i,staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":_vm.btnColor(i)},on:{"click":function($event){return _vm.btnClick(i)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(e)+" ")])]}}],null,true)},[_c('span',[_vm._v("Данные "+_vm._s(e)+" человека")])])})],2),_c('br'),_c('div',{staticClass:"inline"},[_c('h5',[_vm._v("Эти данные будут храниться только на вашем устройстве")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"visibility":"hidden"},attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.gotoKard()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconRight))])],1)]}}])},[_c('span',[_vm._v("Ввести предварительные данные для записи на прием")])])],1),_c('v-card',{staticClass:"mt-4 pa-2",attrs:{"elevation":"10","outlined":""}},[_c('InputFio',{attrs:{"pthis":_vm.THIS}}),_c('InputDr',{attrs:{"pthis":_vm.THIS}}),_c('InputPolicy',{attrs:{"pthis":_vm.THIS}}),_c('InputEmailTel',{attrs:{"pthis":_vm.THIS}}),_c('InputKardOnHands',{attrs:{"pthis":_vm.THIS}}),_c('InputAgree',{attrs:{"pthis":_vm.THIS}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }