import messages from '@/tools/messages'
export default{
    install(Vue, options) {
        Vue.prototype.$msg=function(text){
            //M.toast({html: text})
            console.log('toast msg ',text)
        }
        Vue.prototype.$err=function(err) {
            //M.toast({html: err ,classes: "red"})
            console.log('toast err ',err)
        }
    }
}