import Vue from 'vue'
import VueRouter from 'vue-router'
import Lpu from '../views/Lpu.vue'



Vue.use(VueRouter)

const routes = [
 {
    path: '/',
    name: 'Main',
    meta: {
      layout: 'main'
    },
    component: Lpu
  },
  {
       path: '/lpu/:lpuid/*',
       name: 'Lpu',
       meta: {
           layout: 'main'
       },
       component: Lpu
  },
  {
    path: '/fin',
    name: 'Fin',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Fin')
  },
  {
    path: '/lpuid/:lpuid',
    name: 'Lpuid',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Lpuid')
  },
  {
    path: '/age',
    name: 'Age',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Age.vue')
  },
  {
    path: '/medg',
    name: 'Medg',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Medg.vue')
  },
  {
    path: '/med',
    name: 'Med',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Med.vue')
  },
  {
    path: '/date',
    name: 'Date',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Ddate.vue')
  },
  {
    path: '/time',
    name: 'Time',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Ttime.vue')
  },
  {
    path: '/kard',
    name: 'Kard',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Kard.vue')
  },
  {
    path: '/sta',
    name: 'Sta',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Sta.vue')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Subscribe.vue')
  },
  {
    path: '/test',
    name: 'Test',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Test.vue')
  },
  {
    path: '/ccancel',
    name: 'Ccancel',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Ccancel.vue')
  },
  {
    path: '/stacancel',
    name: 'Stacancel',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/Stacancel.vue')
  },
    {
        path: '/videohelp',
        name: 'Videohelp',
        meta: {
            layout: 'main'
        },
        component: () => import('@/views/Videohelp.vue')
    },
    {
        path: '/*',
        name: 'Default',
        meta: {
            layout: 'main'
        },
        component: Lpu
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})
export default router
