<template>
    <v-container fluid>
        <v-row align="center">
            <v-col
                    class="d-flex"
                    cols="12"
                    md="4"
            >
                <v-select
                        ref="P_DD"
                        v-model="P_DD"
                        :items="arr_dd"
                        item-text="nname"
                        item-value="num"
                        label="День рождения"
                        placeholder="01"
                ></v-select>

            </v-col>
            <v-col
                    class="d-flex"
                    cols="12"
                    md="4"
            >
                <v-select
                        ref="P_MM"
                        v-model="P_MM"
                        :items="arr_mm"
                        item-text="nname"
                        item-value="num"
                        label="Месяц"
                        placeholder="Январь"
                ></v-select>
            </v-col>
            <v-col
                    class="d-flex"
                    cols="12"
                    md="4"
            >
                <v-select
                        ref="P_YYYY"
                        v-model="P_YYYY"
                        :items="arr_yyyy"
                        item-text="nname"
                        item-value="num"
                        label="Год"
                        placeholder="1966"
                ></v-select>
            </v-col>
        </v-row>
    </v-container >

</template>

<script>
    export default {
        name: "InputFio",

        data: () => ({
            /* использует данные родителя через prop pthis
            p_dd: '',
            p_mm: '',
            -p_yyyy: '',
            arr_dd: [],
            arr_mm: [],
            arr_yyyy: [],
            */
            arr_mm: [
                {num: '01', nname: 'Январь', selected: false},
                {num: '02', nname: 'Февраль'},
                {num: '03', nname: 'Март'},
                {num: '04', nname: 'Апрель'},
                {num: '05', nname: 'Май'},
                {num: '06', nname: 'Июнь'},
                {num: '07', nname: 'Июль'},
                {num: '08', nname: 'Август'},
                {num: '09', nname: 'Сентябрь'},
                {num: '10', nname: 'Октябрь'},
                {num: '11', nname: 'Ноябрь'},
                {num: '12', nname: 'Декабрь'}
            ],
            arr_dd: [],
            arr_yyyy: []

        }),
        beforeMount() {
            try {

                let year_b, year_e
                const curyear = new Date().getFullYear()
                year_b = 1900
                year_e = curyear

                //заполнть список дней
                for (let i = 0, dd = 1; dd <= 31; i++, dd++) {
                    this.arr_dd[i] = {num: (dd.toString().length<2?'0':'')+dd, nname: ''+dd, selected: false}
                }

                //заполнть список годов рождения
                for (let i = 0, yyyy = year_b; yyyy <= year_e; i++, yyyy++) {
                    this.arr_yyyy[i] = {num: ''+yyyy, nname: ''+yyyy, selected: false}
                }

            }catch(e){
                console.log(this.name+' beforeMount err=',e)
            }

        },
        computed:{
            P_DD: {
                // getter
                get: function () {
                    return this.pthis.p_dd
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_dd=newValue
                }
            },
            P_MM: {
                // getter
                get: function () {
                    return this.pthis.p_mm
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_mm=newValue
                }
            },
            P_YYYY: {
                // getter
                get: function () {
                    return this.pthis.p_yyyy
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_yyyy=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>