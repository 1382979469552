<template>
  <!--form class="row container"-->
  <div>

    <v-row>
      <v-col
              cols="12"
              sm="6"
      >
        <div class="inline">
            <h2 class="mr-md-3" >{{HEADER}}</h2>

              <!--нужная скрытая кнопка для выравнивания!!! -->
            <v-btn  style="visibility:hidden;"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="grey"
            >
              1
            </v-btn>
       </div>
       <br>

        <!--------------- ФИО и кнопка перехода на форму заполнения данных ФИО -->
        <div class="inline">
          <h5>{{FIO}}</h5>
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2"
                     fab
                     dark
                     small
                     @click="gotoKard()"
                     color="primary"
                     v-bind="attrs"
                     v-on="on"
              >
                <v-icon>{{iconRight}}</v-icon>
              </v-btn>
            </template>
            <span>Ввести предварительные данные для записи на прием</span>
          </v-tooltip>
        </div>


        <!--------------- Карточка  ЛПУ beg -->
        <v-card class="mt-6"
                elevation="10"
                shaped
               v-model="arr"
               v-for="(e,i) in arr"
               :key="e.npp"
        >

          <v-card-title
                  class="primary white--text"
                  v-html="e.name"
          ></v-card-title>

          <v-card-subtitle
                  class="mt-4"
          >{{e.address}}</v-card-subtitle>





          <v-card-actions class="flex-wrap align-content-center">
            <v-btn class="ml-3 mr-3 mt-3  mb-3"
                   color="primary"
                   :value="e.npp"
                   @click.prevent="onNext(i)"
            >
              {{$data.captionSubmit}}
            </v-btn>

            <v-spacer />


            <!--v-btn text
                   @click.prevent="onCancel(i)">
              Еще
            </v-btn-->
            <v-btn class="mt-3 mb-3 ml-3 mr-3"
                   :value="e.npp"
                   @click.prevent="onCancel(i)"
            >
              {{$data.captionCancel}}
            </v-btn>
            <v-spacer />

            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-3"
                       fab
                       small
                       elevation="0"
                       @click="show = !show"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>{{ show ? iconUp : iconDown }}</v-icon>
                </v-btn>
              </template>
              <span>{{ show ? 'Свернуть' : 'Дополнительно1' }}</span>
            </v-tooltip>

          </v-card-actions>

          <!--------------- Дополнительная панель ЛПУ  beg -->

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <!--v-card-text class="text--primary text-left mt-2 text-justify">
                lf jiasl fj;asf
                asdfs'aidf 'as jf'sad f'sadj'fsah f
                as

                 fsa f'ajs 'asdf 'a
              </v-card-text-->
              <v-card-actions class="flex-wrap align-content-center">
                <v-btn class="mt-3 mb-3 ml-3 mr-3"
                       :href="e.telto"
                >
                  {{e.tel}}
                </v-btn>
                <v-spacer />
                <v-btn class="mt-3 mb-3 ml-3 mr-3"
                       :href="e.mailto"
                >
                  Написать главному врачу
                </v-btn>
                <!--v-spacer />

                <v-btn class="mt-3 mb-3 ml-3 mr-3"
                       :value="e.npp"
                       @click.prevent="onCancel(i)"
                >
                  {{$data.captionCancel}}
                </v-btn-->
              </v-card-actions>



            </div>
          </v-expand-transition>
          <!--------------- Дополнительная панель ЛПУ  end -->

        </v-card>
        <!--------------- Карточка  ЛПУ end -->



      </v-col>



      <!--------------- Карточка  ФИО -->
      <v-col
              cols="12"
              sm="6"
      >
        <v-card id="kard"
                class="pa-2"
                elevation="0"
        >
          <div class="inline">

            <h2 class="mr-md-3"
            >
              Ваши данные
            </h2>

            <!-- Кнопки выбора карточки 1,2,3 -->
            <!--InputKardBtn3 :pthis="THIS" /-->
            <v-tooltip bottom
                       v-for="(e,i) in kardCaption"
                       :key="i"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn  class="mx-2"
                        fab
                        dark
                        small
                        :color="btnColor(i)"
                        @click="btnClick(i)"
                        :key="i"
                        v-bind="attrs"
                        v-on="on"
                >
                  {{e}}
                </v-btn>
              </template>
              <span>Данные {{e}} человека</span>
            </v-tooltip>


          </div>

          <br>

          <div class="inline">
            <h5>Эти данные будут храниться только на вашем устройстве</h5>
            <!--нужная скрытая кнопка для выравнивания!!! -->
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="visibility:hidden;"
                      class="mx-2"
                       fab
                       dark
                       small
                       @click="gotoKard()"
                       color="primary"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>{{iconRight}}</v-icon>
                </v-btn>
              </template>
              <span>Ввести предварительные данные для записи на прием</span>
            </v-tooltip>

          </div>


          <v-card
                  class="mt-4 pa-2"
                  elevation="10"
                  outlined
          >
            <!-------------- ФИО -->
            <InputFio :pthis="THIS"/>

            <!-------------- д.р. -->
            <InputDr :pthis="THIS"/>

            <!-------------- полис -->
            <InputPolicy :pthis="THIS"/>

            <!-------------- Email Tel -->
            <InputEmailTel :pthis="THIS"/>

            <!-------------- Амб карта -->
            <InputKardOnHands :pthis="THIS"/>

            <!-------------- Согласие -->
            <InputAgree :pthis="THIS"/>


          </v-card>
        </v-card>
      </v-col>

    </v-row>
  <!--/form-->
  </div>
</template>

<script>

import {lpu, toNext, thisLog} from "@/components/app/js/globals";
import {postLog} from "../tools/post";
import {kardInit,doRest, kardClick, kardSave, kardColor} from "@/components/app/js/kard"; /*kard*/
import InputFio from "@/components/InputFio";
import InputDr from "@/components/InputDr";
import InputPolicy from "@/components/InputPolicy";
import InputEmailTel from "@/components/InputEmailTel";
import InputKardOnHands from "@/components/InputKardOnHands";
import InputAgree from "@/components/InputAgree";
import InputKardBtn3 from "../components/InputKardBtn3";
import {doSave, kardIndex} from "../components/app/js/kard";
import {mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiChevronDown, mdiViewDayOutline} from '@mdi/js';




export default {
  name: 'Lpu',
  data: () => ({
    name: 'Lpu', /*kard*/
    header: 'Выберите больницу',
    loading: true,
    show: false,
    log: true,
    captionSubmit: 'Записаться к врачу',
    captionCancel: 'Просмотр талонов', /*Отмена приема',*/
    arr: [],
    kardCaption: ['1', '2', '3'],
    kardIndex: 0,
    iconLeft: mdiChevronLeft,
    iconRight: mdiChevronRight,
    iconUp: mdiChevronUp,
    iconDown: mdiChevronDown,


    //------------ beg
    p_f: '',
    p_i: '',
    p_o: '',
    p_dd: '',
    p_mm: '',
    p_yyyy: '',
    p_tel: '',
    p_ser: '',
    p_num: '',
    p_email: '',
    p_agree: false,
    kardonhands: false,
    //------------ end

    //---------- не используется
    arr_dd: [],
    arr_mm: [],
    arr_yyyy: [],
    emailRules: [],
    fioRules:[],
    telRules:[],
    numRules:[]



  }),
  computed: {
    THIS(){
      return this
    },
    HEADER() {
      return (this.arr.length === 1 ? 'Выберите' : this.header)
    },
    FIO(){
      let fio=''
      try{
        if(this.p_f!=null){ fio+=' '+this.p_f }
        if(this.p_i!=null){ fio+=' '+this.p_i }
        if(this.p_o!=null){ fio+=' '+this.p_o }
      }catch(e){
        fio=''
      }
      return (fio.trim().length==0)?'Рекомендуется ввести ваши данные':fio
    }
  },
  beforeDestroy() {
    kardSave(this) /*kard*/
  },
  beforeMount() {
    //console.log('lpu beforemount')
    //ЛПУ
    this.arr = lpu(localStorage.getItem('lpuid')) //!!!!
    this.arr.forEach((el, i, arr) => {
      this.arr[i].telto = this.tel2telto(el.tel)
      this.arr[i].mailto = this.email2mailto(el.email)
    })
    //карточка
    kardInit(this) /*kard*/  //kardIndex
    //doRest(this) /*kard*/

  },
  mounted() {
    //console.log('lpu mounted')
    // перенесено из beforeMount, т.к. может быть переход из Ccancel и пр
    //карточка
    kardInit(this) /*kard*/
    doRest(this) /*kard*/
    this.loading = false
  },
  methods: {
    btnColor(i) {
      let ret=kardColor(this,i)
      //console.log('================color i(',i,')','ret(',ret,')')
      return kardColor(this,i) /*kard*/
    },
    btnClick(i) {
      //console.log('>>>>click i(',i,')')
      return kardClick(this,i) /*kard*/
    },
    async onNext(i) {
      kardSave(this) /*kard*/

      await this.$store.dispatch('SET_LPU', this.arr[i])

      //предустановки для ЛПУ (пропустить несколько шагов!)
      if(this.arr[i].preset.length>0){
        for( let j=0; j<this.arr[i].preset.length; j++){
          //console.log(this.arr[i].preset[j])
          await this.$store.dispatch(this.arr[i].preset[j].set_store, this.arr[i].preset[j].arr)
        }
      }

      toNext(this)
    },
    async onCancel(i) {
      kardSave(this) /*kard*/
      await this.$store.dispatch('SET_LPU', this.arr[i])
      await this.$router.push('/ccancel')
    },

    tel2telto(tel) {
      let regex = /\d+?/g;

      let result = 'tel:+'
      let match;
      while (match = regex.exec(tel)) {
        result += match[0]
      }
      return result
    },
    email2mailto(email) {
      //return `mailto:${email}?subject=Обращение с сайта  talon74.ru. Главному врачу&body=Посетив ваш сайт записи на прием к врачу через интернет хочу поделиться своими впечатлениями.`
      return `mailto:${email}?subject=Обращение с сайта  talon74.ru. Главному врачу&body=Приложите как можно больше информации (ФИО, дата рождения, номер полиса, врач и пр.), если необходимо определить причину отказа в записи на прием.`
    },
    gotoKard(){
      this.$vuetify.goTo('#kard', {duration:350,offset:0})
      document.getElementById("family").focus()
    }

  },
  components: {
    InputKardBtn3,
    InputFio, InputDr, InputPolicy, InputEmailTel, InputKardOnHands, InputAgree
  }

}
</script>

<style  scoped>
  .inline{
    align-items:  center;
    display: inline-flex;
  }
</style>
