export default {
    state: {
        arr: {}
    },
    getters:{
        MED: state=> {
            return state.arr
        }
    },
    mutations: {
        SET_MED: (state,payload) =>{
            state.arr=payload
            state.arr.actual='1'
        },
        SET_MED_ACTUAL: (state,actual) =>{
            state.arr.actual=actual
        }
    },
    actions: {
        SET_MED: (context, payload) => {
             context.commit('SET_MED',payload)
        },
        SET_MED_ACTUAL: (context, actual) => {
            context.commit('SET_MED_ACTUAL',actual)
        }
    },

}