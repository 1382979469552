import Vue from 'vue'
import Vuex from 'vuex'
import lpu from './lpu'
import fin from './fin'
import age from './age'
import medg from './medg'
import med from './med'
import ddate from './ddate'
import ttime from './ttime'
import kard from './kard'
import sta from './sta'
import app from './app'
import ccancel from './ccancel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,

  },
  mutations: {
    setError(state, error){
      state.error=error
    },
    clearError(state){
      state.error=null
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
            Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
        );
      }
    },
  },
  getters: {
    error: s=>s.error
  },
  actions: {
  },
  modules: {
    lpu,fin,age,medg,med,ddate,ttime,kard,sta,app,ccancel,global
  }
})
