<template>
    <v-container fluid>
        <v-radio-group v-model="KARDONHANDS">
            <v-radio
                    key="1"
                    label="Амбулаторная карта на руках"
                    :value="true"
            ></v-radio>
            <v-radio
                    key="0"
                    label="Амбулаторная карта  в поликлинике"
                    :value="false"
            ></v-radio>
        </v-radio-group>
    </v-container>

</template>

<script>
    export default {
        name: "InputKardOnHands",

        data: () => ({
            /* использует данные родителя через prop pthis
            kardonhands: false,
            */
            none: ''

        }),
        computed:{
            KARDONHANDS: {
                // getter
                get: function () {
                    return this.pthis.kardonhands
                },
                // setter
                set: function (newValue) {
                    this.pthis.kardonhands=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>