<template>
    <v-container fluid>
        <v-text-field
                id="family"
                ref="P_F"
                v-model="P_F"
                label="Фамилия"
                class-del="mt-8"
                placeholder="Иванов"
                :rules="fioRules"
        ></v-text-field>
        <v-row align="center">
            <v-col
                    class="d-flex"
                    cols="12"
                    md="6"
            >
                <v-text-field
                        ref="P_I"
                        v-model="P_I"
                        label="Имя"
                        placeholder="Иван"
                        :rules="fioRules"
                ></v-text-field>
            </v-col>
            <v-col
                    class="d-flex"
                    cols="12"
                    md="6"
            >

                <v-text-field
                        ref="P_O"
                        v-model="P_O"
                        label="Отчество"
                        placeholder="Иванович"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    export default {
        name: "InputFio",
        data: () => ({
            /* использует данные родителя через prop pthis
            p_f: '',
            p_i: '',
            p_o: '',
            */
            fioRules: [
                v => !!v || 'Обязательно для заполнения!',
                v => ((v && v.trim()) || '').indexOf(' ') < 0 || 'Пробелы не допустимы',
                v => (v && v.length > 1) || 'Должно быть больше одного символа',
                v => /^[A-ZА-ЯЁ\s-]+$/i.test(v) || 'Должны быть только буквы или дефис'
                //v => /^(\d+)$/.test(v) || 'Должны быть только буквы'
            ],
        }),
        computed:{
            P_F: {
                // getter
                get: function () {
                    return this.pthis.p_f
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_f=newValue
                }
            },
            P_I: {
                // getter
                get: function () {
                    return this.pthis.p_i
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_i=newValue
                }
            },
            P_O: {
                // getter
                get: function () {
                    return this.pthis.p_o
                },
                // setter
                set: function (newValue) {
                    this.pthis.p_o=newValue
                }
            }

        },
        props: { pthis: Object }

    }
</script>

<style scoped>

</style>