//---------------------------------------------------------------------------------------------------
// i='' текущие рабочие данные
// i-undefined - текущи банк памяти kardIndex
function doSave(thiss, i) {

    if(thiss==null || typeof thiss === 'undefined'){
       console.log('doSave - не передан this!!!')
        return //-----
    }



    let flags={
        p_f:            false,
        p_i:            false,
        p_o:            false,
        p_dd:           false,
        p_mm:           false,
        p_yyyy:         false,
        p_tel:          false,
        p_ser:          false,
        p_num:          false,
        p_email:        false,
        p_agree:        false,
        kardonhands:    false,
    }

    switch(thiss.name){
        case 'Lpu':
            for (let prop in flags) {
                flags[prop]=true
            }
            break
        case 'Ccancel':
            flags.p_num=true
            flags.p_dd=true
            flags.p_mm=true
            flags.p_yyyy=true
            flags.p_agree=true
            break
    }

    //сохранить значения в localStorage
    if(flags.p_f){          localStorage.setItem('p_f' + i, thiss.p_f ? thiss.p_f : '')  }
    if(flags.p_i){          localStorage.setItem('p_i' + i, thiss.p_i ? thiss.p_i : '')  }
    if(flags.p_o){          localStorage.setItem('p_o' + i, thiss.p_o ? thiss.p_o : '')  }

    if(flags.p_dd){         localStorage.setItem('p_dd' + i, thiss.p_dd ? thiss.p_dd : '')  }
    if(flags.p_mm){         localStorage.setItem('p_mm' + i, thiss.p_mm ? thiss.p_mm : '')  }
    if(flags.p_yyyy){       localStorage.setItem('p_yyyy' + i, thiss.p_yyyy ? thiss.p_yyyy : '')  }

    if(flags.p_tel){        localStorage.setItem('p_tel' + i, thiss.p_tel ? thiss.p_tel : '')  }
    if(flags.p_ser){        localStorage.setItem('p_ser' + i, thiss.p_ser ? thiss.p_ser : '')  }
    if(flags.p_num){        localStorage.setItem('p_num' + i, thiss.p_num ? thiss.p_num : '') }

    if(flags.p_email){      localStorage.setItem('p_email' + i, thiss.p_email ? thiss.p_email : '') }
    if(flags.p_kardonhands){localStorage.setItem('kardonhands' + i, thiss.kardonhands ? thiss.kardonhands : 'false')  }

    if(flags.p_agree){      localStorage.setItem('p_agree', thiss.p_agree ? thiss.p_agree : 'false') } // для всех одна установка




}

//---------------------------------------------------------------------------------------------------
// i='' текущие рабочие данные
// i-undefined - текущи банк памяти kardIndex
function doRest(thiss, i) {
    if(thiss==null || typeof thiss === 'undefined'){
        console.log('doRest - не передан this!!!')
        return //-----
    }


    if (i == null || typeof i === 'undefined') {
        i = kardIndex(thiss) // текущий банк памяти
    }
    thiss.p_f = localStorage.getItem('p_f' + i)
    thiss.p_i = localStorage.getItem('p_i' + i)
    thiss.p_o = localStorage.getItem('p_o' + i)
    thiss.p_dd = localStorage.getItem('p_dd' + i)
    thiss.p_mm = localStorage.getItem('p_mm' + i)
    thiss.p_yyyy = localStorage.getItem('p_yyyy' + i)
    thiss.p_tel = localStorage.getItem('p_tel' + i)
    thiss.p_ser = localStorage.getItem('p_ser' + i)
    thiss.p_num = localStorage.getItem('p_num' + i)
    thiss.p_email = localStorage.getItem('p_email' + i)
    thiss.kardonhands = localStorage.getItem('kardonhands' + i) === 'true'

    thiss.p_agree = localStorage.getItem('p_agree') === 'true' // для всех одна установка
    thiss.kardIndex = localStorage.getItem('kardIndex') // для всех одна установка
}
//---------------------------------------------------------------------------------------------------
function kardIndex(thiss,i) {
    if(thiss==null || typeof thiss === 'undefined'){
        console.log('kardIndex - не передан this!!!')
        return //-----
    }


    let ret = (i == null || typeof i === 'undefined') ? localStorage.getItem('kardIndex') : i

    if (ret == null || typeof ret === 'undefined') {
        ret = '0'
    }

    localStorage.setItem('kardIndex', ret)
    thiss.kardIndex = ret

    return ret
}

//---------------------------------------------------------------------------------------------------
function kardClick(thiss,i) {

    if(thiss==null || typeof thiss === 'undefined'){
        console.log('kardClick - не передан this!!!')
        return //-----
    }

    try {
        doSave(thiss, kardIndex(thiss))
    }catch(err){
        console.log('kardClick.1 err ',err)
    }
    try {
        doSave(thiss) //в текущие данные
    }catch(err){
        console.log('kardClick.2 err ',err)
    }
    try {
        kardIndex(thiss, i)
    }catch(err){
        console.log('kardClick.3 err ',err)
    }
    try {
        doRest(thiss, kardIndex(thiss))
    }catch(err){
        console.log('kardClick.4 err ',err)
    }
}
//---------------------------------------------------------------------------------------------------
function kardSave(thiss) {

    if(thiss==null || typeof thiss === 'undefined'){
        console.log('kardSave - не передан this!!!')
        return //----
    }


    doSave(thiss, kardIndex(thiss))
    doSave(thiss) // в текущие данные

}
//---------------------------------------------------------------------------------------------------
function kardColor(thiss,i) {
    if(thiss==null || typeof thiss === 'undefined'){
        console.log('kardColor - не передан this!!!')
        return //----
    }

    return i == kardIndex(thiss) ? 'primary' : 'grey'
}

//---------------------------------------------------------------------------------------------------
function kardInit(thiss) {

    if(thiss==null || typeof thiss === 'undefined'){
        console.log('kardInit - не передан this!!!')
        return //----
    }


    thiss.arr_mm = [
        {num: '01', nname: 'Январь', selected: false},
        {num: '02', nname: 'Февраль'},
        {num: '03', nname: 'Март'},
        {num: '04', nname: 'Апрель'},
        {num: '05', nname: 'Май'},
        {num: '06', nname: 'Июнь'},
        {num: '07', nname: 'Июль'},
        {num: '08', nname: 'Август'},
        {num: '09', nname: 'Сентябрь'},
        {num: '10', nname: 'Октябрь'},
        {num: '11', nname: 'Ноябрь'},
        {num: '12', nname: 'Декабрь'}
    ]

    //v => !!v || 'Обязательно для заполнения!',
    //v => /.+@.+/.test(v) || 'Некорректный E-mail'
    thiss.emailRules= [
        v => /.+@.+/.test(v) || 'Некорректный E-mail'

    ],
    thiss.fioRules=[
        v => !!v || 'Обязательно для заполнения!',
        v => ((v && v.trim()) || '').indexOf(' ') < 0 || 'Пробелы не допустимы',
        v => (v && v.length >1) || 'Должно быть больше одного символа',
        v => /^[A-ZА-ЯЁ\s-]+$/i.test(v) || 'Должны быть только буквы или дефис'
        //v => /^(\d+)$/.test(v) || 'Должны быть только буквы'
    ]
    /*
    thiss.telRules=[
        v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
        v => (v && v.length == 11) || 'Телефон должен содержать 11 цифр'
    ]
    */
    thiss.telRules=[
        v => !!v || 'Обязательно для заполнения!'
    ]

    thiss.numRules=[
        v => !!v || 'Обязательно для заполнения',
        v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
        v => (v && v.length >=6 && v.length <=16) || 'Количество цифр полиса должно быть от 6 до 16'
    ]


    //------------------- Kard
    try {

        let year_b, year_e
        const curyear = new Date().getFullYear()
        year_b = 1900
        year_e = curyear

        //заполнть список дней
        for (let i = 0, dd = 1; dd <= 31; i++, dd++) {
            thiss.arr_dd[i] = {num: (dd.toString().length<2?'0':'')+dd, nname: ''+dd, selected: false}
        }

        //заполнть список годов рождения
        for (let i = 0, yyyy = year_b; yyyy <= year_e; i++, yyyy++) {
            thiss.arr_yyyy[i] = {num: ''+yyyy, nname: ''+yyyy, selected: false}
        }

    }catch(e){
        console.log(thiss.name+' kardInit err=',e)
    }
    thiss.kardCaption=['1', '2', '3']

    kardIndex(thiss,kardIndex(thiss))

}



// список экспортируемых переменных
export {doSave, doRest, kardIndex, kardSave, kardClick, kardColor, kardInit}


